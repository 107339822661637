import React, { useState, useEffect } from 'react';

const ChevronLeft = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M15 18l-6-6 6-6" />
  </svg>
);

const ChevronRight = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);

const RoadmapSlider = () => {
  const roadmapItems = [
    { index: 1, title: 'Ask-to-Earn Gameplay Launch', description: 'Interact with Regen AI and get rewarded! Simply ask your cryptocurrency-related questions, and earn tokens while expanding your knowledge. The smarter you ask, the more you earn!', date: 'March 2025' },
    { index: 2, title: 'CoinGecko & CoinMarketCap Listing', description: 'Official listings on CoinGecko and CoinMarketCap, increasing visibility and credibility within the crypto space.', date: 'March 2025' },
    { index: 3, title: 'Exchange Listings Begin', description: 'Bringing Regen AI tokens to major exchanges, enhancing liquidity and accessibility for our growing community.', date: 'April 2025' },
    { index: 4, title: 'Meta Gameplay Integration', description: 'We are evolving! Meta gameplay integration will revolutionize our platform, making it even more dynamic, immersive, and adaptive to the latest trends in gaming and AI.', date: 'May 2025' },
    { index: 5, title: 'Token Unlock Event', description: 'The moment you’ve been waiting for! Token unlock will empower our holders and early adopters, fueling the next phase of growth and utility in the Regen AI ecosystem.', date: 'August 2025' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  
  const itemsPerPage = window.innerWidth < 768 ? 1 : 3;

  const handleNext = () => {
    if (currentIndex + itemsPerPage < roadmapItems.length) {
      setCurrentIndex(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const visibleItems = roadmapItems.slice(currentIndex, currentIndex + itemsPerPage);
  
  const canGoNext = currentIndex + itemsPerPage < roadmapItems.length;
  const canGoPrev = currentIndex > 0;

  useEffect(() => {
    const timer = setInterval(() => {
      if (canGoNext) {
        handleNext();
      } else {
        setCurrentIndex(0); // Reset to the first item if at the end
      }
    }, 2500); // Change slide every 3.5 seconds

    return () => clearInterval(timer); // Cleanup on unmount
  }, [currentIndex, canGoNext]);

  return (
    <div id="roadmap" className="white-background">
      <div className="w-full py-16 road-map-section">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center mb-16">
            <h1 className="bg-liner-green text-4xl md:text-5xl font-bold">Our Roadmap</h1>
            
            <div className="flex gap-4">
              <button 
                onClick={handlePrev}
                disabled={!canGoPrev}
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors ${
                    canGoPrev 
                    ? 'solid-green-background hover:bg-gray-200 cursor-pointer' 
                    : 'bg-gray-600 cursor-not-allowed'
                }`}
              >
                <ChevronLeft />
              </button>
              <button 
                onClick={handleNext}
                disabled={!canGoNext}
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors ${
                    canGoNext 
                    ? 'solid-green-background hover:bg-gray-200 cursor-pointer' 
                    : 'bg-gray-600 cursor-not-allowed'
                }`}
              >
                <ChevronRight />
              </button>
            </div>
          </div>
          <div className="text-center mb-8">
            <h2 className="text-xl md:text-2xl font-semibold text-white">
              🚀 Regen AI Crypto Project Roadmap: Pioneering the Future of AI & Rewards! 🚀
            </h2>
          </div>

          <div className="transition-all duration-500 ease-in-out opacity-100 transform translate-y-0 grid grid-cols-1 lg:grid-cols-3 gap-6">
            {visibleItems.map((item, index) => (
              <div 
                key={currentIndex + index} 
                className="bg-[#13132b] rounded-lg p-8 shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1 flex flex-col justify-between"
                style={{ minWidth: '300px' }} // Set a minimum width
              >
                <div className="flex flex-col gap-4">
                  <div className="text-gray-400 text-sm tracking-wider">
                    {item.date}
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-3 h-3 rounded-full bg-purple-500" />
                    <p style={{color:"#fff"}}>{item.index})</p>
                    <h3 className="text-white text-xl md:text-2xl font-bold">{item.title}</h3>
                  </div>
                  <p className="text-gray-400 leading-relaxed text-sm md:text-base">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <h2 className="text-xl md:text-2xl font-semibold text-white">
              🚀 Join us on this groundbreaking journey as we redefine AI, crypto rewards, and interactive learning! 🚀
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapSlider;
